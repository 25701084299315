export default function (minutes: number, forceDoubleDigitHours = true, forceDoubleDigitMinutes = true) {
  let hours = Math.floor(minutes / 60).toString()
  if (forceDoubleDigitHours)
    hours = hours.padStart(2, '0')

  let minutesLeft = Math.ceil(minutes % 60).toString()
  if (forceDoubleDigitMinutes)
    minutesLeft = minutesLeft.padStart(2, '0')

  return { hours, minutes: minutesLeft }
}
